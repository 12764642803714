import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { getFullPath, removeTrailingSlash, addHttps } from 'utils/urls';

/* eslint-disable react/no-unknown-property */
export default function Page({
  backgroundColor = '#fff',
  children,
  color,
  description,
  image,
  isDark,
  location,
  social,
  title,
}) {
  const fullPath = removeTrailingSlash(
    getFullPath(process.env.SITE_URL, location),
  );
  const fullImagePath = addHttps(image);
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={fullPath} />
        <meta property="fb:app_id" content="748502052183113" />

        {image && <meta property="og:image" content={fullImagePath} />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`@${social.twitter}`} />
        <meta name="twitter:creator" content={`@${social.twitter}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta property="twitter:image" content={fullImagePath} />}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />

        <meta name="apple-mobile-web-app-title" content={title} />
        <meta name="application-name" content={title} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="canonical" href={fullPath} />
        <script type="text/javascript" src="/iubenda.js" />
        <style type="text/css">{`
          body {
              background-color: ${isDark ? '#000' : backgroundColor};
              color: ${color};
          }
        `}</style>
      </Helmet>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  location: PropTypes.object.isRequired,
  social: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

Page.defaultProps = {
  color: undefined,
  image: undefined,
};
