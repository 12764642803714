import styled, { css } from 'styled-components';
import { space, color } from 'styled-system';

import { ISSUE_LENGTH } from 'constants/padding';

export const ButtonBar = styled.div`
  ${space};
  height: ${ISSUE_LENGTH}px;
  width: 100%;
  left: 0;
  top: 0;
  position: sticky;
  z-index: 1;

  &::before,
  &::after {
    position: absolute;
    content: '';
    left: 0;
    height: 1px;
    width: 100%;
    opacity: 0.1;
    background-color: currentColor;
  }

  &::before {
    top: -1px;
  }

  &::after {
    bottom: -1px;
  }
`;

export const Filler = styled.div`
  ${color};
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.div`
  width: ${ISSUE_LENGTH}px;
  height: ${ISSUE_LENGTH}px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    opacity: 0.1;
    background-color: currentColor;
  }

  ${props =>
    props.left &&
    css`
      &::before {
        content: '';
        left: -1px;
      }
    `};

  ${props =>
    props.right &&
    css`
      &::after {
        content: '';
        right: 0;
      }
    `};
`;
