import styled from 'styled-components';
import { width } from 'styled-system';

const Line = styled.div`
  ${width};
  height: 1px;
  opacity: 0.1;
  background-color: currentColor;
`;

export default Line;
