import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Icons/Dropdown';
import PageWrap from 'components/PageWrap';

import { Select, Icon, Overlay } from './styles';

import Issues from './Issues';

class IssueSelect extends PureComponent {
  state = {
    isModalOpen: false,
  };

  toggleModalState = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  };

  navigateToPage = () => {};

  render() {
    const { isModalOpen } = this.state;
    const { activeIssue, issues } = this.props;
    return (
      <div>
        <Overlay bg="white" opacity={isModalOpen ? 0.9 : 0} />
        <PageWrap space={3}>
          <Select py={20} mb={2} fontSize={3} onClick={this.toggleModalState}>
            {issues.map(
              ({ node }) =>
                activeIssue === node.number && (
                  <div key={node.number}>
                    Nº
                    {node.number}
                    <Icon rotated={isModalOpen}>
                      <Dropdown />
                    </Icon>
                  </div>
                ),
            )}
          </Select>
        </PageWrap>
        <Issues
          isOpen={isModalOpen}
          activeIssue={activeIssue}
          issues={issues}
        />
      </div>
    );
  }
}

IssueSelect.propTypes = {
  activeIssue: PropTypes.number.isRequired,
  issues: PropTypes.array.isRequired,
};

export default IssueSelect;
