import styled from 'styled-components';
import {
  borderRadius,
  color,
  display,
  fontFamily,
  fontSize,
  maxWidth,
  opacity,
  position,
  space,
  width,
} from 'styled-system';

export const BoxElement = styled.div`
  ${borderRadius};
  ${color};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${maxWidth};
  ${opacity};
  ${position};
  ${space};
  ${width};
  transition: all 300 ease-in-out;
  position: ${props => props.position};
  height: ${props => props.height};
`;
