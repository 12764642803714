import styled from 'styled-components';
import { space, fontSize, opacity } from 'styled-system';

export const IssuesDropdown = styled.div`
  ${opacity};
  width: 100%;
  background-color: currentColor;
  position: absolute;
  z-index: 2;
  margin-top: -17px;
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  transform: translateY(${props => (props.isOpen ? '0' : '20px')});
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const IssueItem = styled.div`
  ${space};
  ${fontSize};
  color: ${props => (props.isActive ? 'rgba(255, 255, 255, 0.5)' : 'white')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &::last-child {
    border-bottom: none;
  }
`;
