import React from 'react';

import Svg from '../../Svg';

export default function Close(props) {
  return (
    <Svg width={10} height={10} viewBox="0 0 10 10" {...props}>
      <path
        d="M5,4.23,9.23,0,10,.77,5.77,5,10,9.23,9.23,10,5,5.77.77,10,0,9.23,4.23,5,0,.77.77,0Z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
