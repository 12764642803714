/**
 *
 * Image
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { loadImage } from 'utils/image';
import { Image } from './styles';

class ImageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
    };
  }

  componentDidMount() {
    this.preloadImage();
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (src !== prevProps.src) {
      this.preloadImage();
    }
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  preloadImage() {
    const { src } = this.props;
    loadImage(src).then(() => {
      if (!this.isCancelled) {
        this.setState({ imageLoaded: true });
      }
    });
  }

  render() {
    const { alt, src } = this.props;
    const { imageLoaded } = this.state;
    return <Image alt={alt} src={src} opacity={imageLoaded ? 1 : 0} />;
  }
}

ImageComponent.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
};

ImageComponent.defaultProps = {
  alt: undefined,
};

export default ImageComponent;
