import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import { getIssueUrl } from 'utils/urls';
import ColNumber from 'components/ColNumber';

import { Col, Border } from './styles';

function Column({ number, filler, right, children, slug }) {
  const rightPosition = right ? 0 : 'auto';
  const leftPosition = !right ? 0 : 'auto';
  const border = (
    <Border
      top={['auto', 'auto', 0, 0]}
      bottom={[0, 0, 'auto', 'auto']}
      width={['100%', '100%', '1px', '1px']}
      height={['1px', '1px', '100%', '100%']}
      right={rightPosition}
      left={leftPosition}
    />
  );

  if (children) {
    return (
      <Col filler={filler} right={right}>
        {children}
        {border}
      </Col>
    );
  }

  return (
    <Col filler={filler}>
      {filler ? (
        <ColNumber py={2} isActive>
          Nº
          {number}
        </ColNumber>
      ) : (
        <Link to={getIssueUrl(number, slug)}>
          <ColNumber py={2}>
            Nº
            {number}
          </ColNumber>
        </Link>
      )}
      {border}
    </Col>
  );
}

Column.propTypes = {
  children: PropTypes.node,
  filler: PropTypes.bool,
  number: PropTypes.number,
  right: PropTypes.bool,
  slug: PropTypes.string,
};

Column.defaultProps = {
  children: undefined,
  filler: false,
  number: undefined,
  right: false,
  slug: undefined,
};

export default Column;
