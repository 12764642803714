import React from 'react';
import Heading from 'components/Heading';

/* eslint-disable react/prop-types */
const HeadingComponent = ({ fontSize, tag }) => props => (
  <Heading tag={tag} fontSize={fontSize} mt={4} mb={0}>
    {props.children}
  </Heading>
);

export default HeadingComponent;
