import React from 'react';
import PropTypes from 'prop-types';

import theme from 'utils/theme.json';

import Wrap from './styles';

function PageWrap({ children, size, space, maxWidth, width }) {
  return (
    <Wrap
      px={space}
      size={theme.breakpoints[size]}
      maxWidth={maxWidth}
      width={width}
    >
      {children}
    </Wrap>
  );
}

PageWrap.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number,
  size: PropTypes.oneOf(Object.keys(theme.breakpoints).map(Number)),
  space: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  width: PropTypes.string,
};

PageWrap.defaultProps = {
  maxWidth: undefined,
  size: 3,
  space: 0,
  width: undefined,
};

export default PageWrap;
