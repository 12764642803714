import React, { Component } from 'react';

import Button from '../Button';
import Close from '../Icons/Close';
import Heading from '../Heading';
import NewsletterForm from '../NewsletterForm';
import PinRight from '../PinRight';

import Anchor from '../Anchor';
import Text from '../Text';

import { Modal, Dialog, ClickHandler, Hover } from './styles';

export default class NewsletterContainer extends Component {
  state = {
    isModalActive: false,
  };

  onToggleModal = () => {
    this.setState(state => ({ isModalActive: !state.isModalActive }));
  };

  componentDidMount = () => {
    this.setState({
      isModalActive:
        typeof window !== 'undefined'
          ? window.location.hash === '#subscribe'
          : false,
    });
  };

  render() {
    const { isDark } = this.props;
    const { isModalActive } = this.state;
    const color = isDark ? 'black' : 'white';
    return (
      <div>
        <Modal
          display={['block', 'flex', 'flex', 'flex']}
          isActive={isModalActive}
          isDark={isDark}
        >
          <ClickHandler onClick={this.onToggleModal} />
          <Dialog
            isActive={isModalActive}
            m={[0, 'auto', 'auto', 'auto']}
            p={[25, 30, 40, 50]}
          >
            <Heading mb={40} fontSize={5} color={color}>
              Get Mal&apos;s newsletter
            </Heading>
            <NewsletterForm isDark={isDark} />
            <Hover>
              <PinRight p={2} onClick={this.onToggleModal}>
                <Close fill="white" />
              </PinRight>
            </Hover>
          </Dialog>
        </Modal>
        <Text fontSize={2}>
          <Anchor onClick={this.onToggleModal}>
            Subscribe to our newsletter
          </Anchor>
        </Text>
      </div>
    );
  }
}
