import React from 'react';
import { ButtonElement, TextElement } from './styles';

export default function Button({ children, color, hoverColor, ...rest }) {
  return (
    <ButtonElement {...rest}>
      <TextElement color={color} hoverColor={hoverColor}>
        {children}
      </TextElement>
    </ButtonElement>
  );
}
