import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { Button, Inner } from './styles';

function SquareButton({ children, kind, onClick }) {
  return (
    <Button kind={kind} onClick={onClick}>
      <Inner>{children}</Inner>
    </Button>
  );
}

SquareButton.propTypes = {
  kind: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

SquareButton.defaultProps = {
  kind: 'light',
  onClick: noop,
};

export default SquareButton;
