import styled from 'styled-components';

import {
  borderRadius,
  bottom,
  color,
  display,
  height,
  left,
  minHeight,
  position,
  right,
  space,
  top,
  width,
  zIndex,
} from 'styled-system';

import { transform } from '../../utils/customProperties';

export const PositionElement = styled.div`
  ${borderRadius};
  ${bottom};
  ${color};
  ${display};
  ${height};
  ${left};
  ${minHeight};
  ${position};
  ${right};
  ${space};
  ${top};
  ${transform};
  ${width};
  ${zIndex};
`;
