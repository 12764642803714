import styled from 'styled-components';
import { space, fontSize, color, opacity } from 'styled-system';

export const Overlay = styled.div`
  ${color};
  ${opacity};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const Select = styled.div`
  ${space};
  ${fontSize};
  width: 100%;
  color: currentColor;
  fill: currentColor;
  position: relative;
  z-index: 2;

  &::after {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background-color: currentColor;
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -3px;
  transform: ${props => (props.rotated ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
`;
