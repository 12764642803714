import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import Arrow from 'components/Icons/Arrow';
import Facebook from 'components/Icons/Facebook';
import Mail from 'components/Icons/Mail';
import SquareButton from 'components/SquareButton';
import Twitter from 'components/Icons/Twitter';

import Wrap from './styles';

function Sidebar({
  backUrl,
  emailProps,
  onBackButtonClick,
  quote,
  shareUrl,
  social,
}) {
  return (
    <Wrap>
      {backUrl ? (
        <Link to={backUrl}>
          <SquareButton>
            <Arrow />
          </SquareButton>
        </Link>
      ) : (
        <SquareButton onClick={onBackButtonClick}>
          <Arrow />
        </SquareButton>
      )}
      <FacebookShareButton url={shareUrl} quote={quote}>
        <SquareButton>
          <Facebook size={10} />
        </SquareButton>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={quote} via={social.twitter}>
        <SquareButton>
          <Twitter size={18} />
        </SquareButton>
      </TwitterShareButton>
      <EmailShareButton {...emailProps}>
        <SquareButton>
          <Mail size={16} />
        </SquareButton>
      </EmailShareButton>
    </Wrap>
  );
}

Sidebar.propTypes = {
  backUrl: PropTypes.string.isRequired,
  emailProps: PropTypes.object.isRequired,
  onBackButtonClick: PropTypes.func,
  quote: PropTypes.string.isRequired,
  shareUrl: PropTypes.string,
  social: PropTypes.object.isRequired,
};

export default Sidebar;
