import styled from 'styled-components';

import {
  borderRadius,
  boxShadow,
  color,
  display,
  flex,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  letterSpacing,
  lineHeight,
  maxWidth,
  minHeight,
  space,
  textAlign,
  width,
} from 'styled-system';

import {
  hoverColor,
  hoverBoxShadow,
  hoverBg,
  focusColor,
  focusBoxShadow,
  focusBg,
  pointerEvents,
  resize,
} from '../../utils/customProperties';

export const InputElement = styled.input`
  ${borderRadius};
  ${boxShadow};
  ${color};
  ${display};
  ${flex};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${letterSpacing};
  ${lineHeight};
  ${maxWidth};
  ${minHeight};
  ${pointerEvents};
  ${resize};
  ${space};
  ${textAlign};
  ${width};
  box-shadow: ${props =>
    props.isDark
      ? '0 1px 0 0 rgba(0, 0, 0, 0.2)'
      : '0 1px 0 0 rgba(255, 255, 255, 0.4)'};
  appearance: none;
  border-width: 0;
  outline-style: none;
  transition-property: all;
  transition-duration: 150ms;
  outline-color: transparent;
  outline-offset: 4px;
  outline-style: dashed;
  outline-width: 1px;
  box-sizing: border-box;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    box-shadow: ${props =>
      props.isDark
        ? '0 1px 0 0 rgba(0, 0, 0, 0.4)'
        : '0 1px 0 0 rgba(255, 255, 255, 0.6)'};
    ${hoverColor};
    ${hoverBoxShadow};
    ${hoverBg};
  }

  &:focus {
    box-shadow: ${props =>
      props.isDark
        ? '0 1px 0 0 rgba(0, 0, 0, 0.6)'
        : '0 1px 0 0 rgba(255, 255, 255, 0.8)'};
    ${focusColor};
    ${focusBoxShadow};
    ${focusBg};
  }

  ::-webkit-input-placeholder {
    line-height: normal;
    color: ${props =>
      props.isDark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.5)'};
  }

  ::-moz-input-placeholder {
    line-height: normal;
    color: ${props =>
      props.isDark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.5)'};
  }
`;

export const LoaderWrapper = styled.div`
  ${width};
  ${height};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;
