module.exports = {
  getIssueUrl(issueNumber, issueSlug) {
    return `/${issueNumber}/${issueSlug}`;
  },

  getAuthorUrl(issueNumber, issueSlug, authorName) {
    return `${module.exports.getIssueUrl(
      issueNumber,
      issueSlug,
    )}/${authorName}`;
  },

  getPostUrl(issueNumber, issueSlug, authorName, postSlug) {
    return `${module.exports.getAuthorUrl(
      issueNumber,
      issueSlug,
      authorName,
    )}/${postSlug}/`;
  },

  getPageUrl(slug) {
    return `/${slug}`;
  },

  getGlobalAuthorUrl(slug) {
    return `/${slug}/`;
  },

  getFullPath(baseUrl, location) {
    return baseUrl + location.pathname;
  },

  getTwitterUrl(handle) {
    return `https://twitter.com/${handle}`;
  },

  getFacebookUrl(username) {
    return `https://facebook.com/${username}`;
  },

  getInstagramUrl(username) {
    return `https://instagram.com/${username}`;
  },

  removeTrailingSlash(url) {
    const lastChar = url.substr(-1);
    if (lastChar !== '/') {
      return url.replace(/\/$/, '');
    }
    return url;
  },

  addHttps(url) {
    return `https:${url}`;
  },
};
