import { style } from 'styled-system';

export const borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius',
  cssProperty: 'border-top-left-radius',
  key: 'radii',
});

export const borderTopRightRadius = style({
  prop: 'borderTopRightRadius',
  cssProperty: 'border-top-right-radius',
  key: 'radii',
});

export const borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius',
  cssProperty: 'border-bottom-right-radius',
  key: 'radii',
});

export const borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius',
  cssProperty: 'border-bottom-left-radius',
  key: 'radii',
});

export const borderWidth = style({
  prop: 'borderWidth',
  cssProperty: 'border-width',
});

export const cursor = style({
  prop: 'cursor',
  cssProperty: 'cursor',
});

export const fill = style({
  prop: 'fill',
  cssProperty: 'fill',
  key: 'colors',
});

export const stroke = style({
  prop: 'stroke',
  cssProperty: 'stroke',
  key: 'colors',
});

export const hoverColor = style({
  prop: 'hoverColor',
  cssProperty: 'color',
  key: 'colors',
});

export const hoverBg = style({
  prop: 'hoverBg',
  cssProperty: 'background-color',
  key: 'colors',
});

export const hoverFill = style({
  prop: 'hoverFill',
  cssProperty: 'fill',
  key: 'colors',
});

export const hoverOpacity = style({
  prop: 'hoverOpacity',
  cssProperty: 'opacity',
});

export const hoverBoxShadow = style({
  prop: 'hoverBoxShadow',
  cssProperty: 'box-shadow',
  key: 'shadows',
});

export const focusColor = style({
  prop: 'focusColor',
  cssProperty: 'color',
  key: 'colors',
});

export const focusBg = style({
  prop: 'focusBg',
  cssProperty: 'background-color',
  key: 'colors',
});

export const focusBoxShadow = style({
  prop: 'focusBoxShadow',
  cssProperty: 'box-shadow',
  key: 'shadows',
});

export const objectFit = style({
  prop: 'objectFit',
  cssProperty: 'object-fit',
});

export const objectPosition = style({
  prop: 'objectPosition',
  cssProperty: 'object-position',
});

export const transform = style({
  prop: 'transform',
  cssProperty: 'transform',
});

export const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'text-transform',
});

export const pointerEvents = style({
  prop: 'pointerEvents',
  cssProperty: 'pointer-events',
});

export const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'white-space',
});

export const overflow = style({
  prop: 'overflow',
  cssProperty: 'overflow',
});

export const overflowY = style({
  prop: 'overflowY',
  cssProperty: 'overflow',
});

export const maxHeight = style({
  prop: 'maxHeight',
  cssProperty: 'max-height',
});

export const transitionDuration = style({
  prop: 'transitionDuration',
  cssProperty: 'transition-duration',
});

export const resize = style({
  prop: 'resize',
  cssProperty: 'resize',
});
