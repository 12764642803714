import styled from 'styled-components';
import { fontSize, space, color } from 'styled-system';

/* eslint-disable jsx-a11y/anchor-has-content */
const Wrap = styled.p`
  ${fontSize};
  ${space};
  ${color};
  opacity: ${props => props.opacity};
  display: ${props => props.display};
  font-family: ${props => props.fontFamily};
  font-weight: ${props => props.fontWeight};
  line-height: 1.6em;
  max-width: ${props =>
    props.maxWidth !== null ? `${props.maxWidth}px` : '100%'};
`;

export default Wrap;
