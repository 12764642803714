import styled from 'styled-components';

const SIZE = 16;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${SIZE}px;
  width: ${SIZE}px;
  background-color: ${props =>
    props.isDark ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  transition: all 200ms ease-in-out;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 3px;
    height: 6px;
    border: solid currentColor;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 200ms ease-in-out;
  }
`;

export const Container = styled.label`
  color: currentColor;
  display: block;
  position: relative;
  padding-left: ${SIZE + 10}px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  transition: all 200ms ease-in-out;

  &:hover ${Input} ~ ${Checkmark} {
    background-color: ${props =>
      props.isDark ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)'};
  }

  ${Input}:checked ~ ${Checkmark} {
    background-color: ${props =>
      props.isDark ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
  }

  ${Input}:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export const Text = styled.span`
  color: ${props => (props.isDark ? '#000' : '#fff')};
`;
