import React from 'react';
import Link from 'gatsby-link';

import Box from 'components/Box';
import PageWrap from 'components/PageWrap';

import { Svg } from './styles';

export default function Logo() {
  return (
    <PageWrap maxWidth={42}>
      <Box py={3}>
        <Link to="/">
          <Svg width="60" viewBox="0 0 678 586">
            <path d="M569,586A109.1,109.1,0,0,1,460,477.07V102.83H448v34.24c0,.4,0,.8,0,1.2v338.8a109,109,0,0,1-218,0V210.65c0-.5,0-1,0-1.47V166.32a63.51,63.51,0,0,0-127,0V476.18a6,6,0,1,0,12,0v-310a51.5,51.5,0,0,1,103,0v310c0,60-48.9,108.86-109,108.86S0,536.21,0,476.18V166.1C.12,74.49,74.77,0,166.51,0S333,74.61,333,166.32v241c0,.49,0,1,0,1.46v68.27a6,6,0,1,0,12,0V191.38c0-.4,0-.8,0-1.2V51.41A51.45,51.45,0,0,1,396.49,0h115A51.45,51.45,0,0,1,563,51.41V477.07a6,6,0,1,0,12,0V465.22a51.5,51.5,0,0,1,103,0v11.85A109.09,109.09,0,0,1,569,586Z" />
          </Svg>
        </Link>
      </Box>
    </PageWrap>
  );
}
