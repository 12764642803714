import styled from 'styled-components';
import { fontSize, position, left } from 'styled-system';

const Wrap = styled.div`
  ${fontSize};
  ${left};
  ${position};
  color: currentColor;
  bottom: 1px;
  opacity: 0.4;
`;

export default Wrap;
