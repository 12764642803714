import React from 'react';

function Arrow() {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16">
      <path
        d="M8.31,0,9.69,1.34,3.83,7.05H22V9H3.83l5.86,5.71L8.31,16,0,8Z"
        transform="translate(0)"
      />
    </svg>
  );
}

export default Arrow;
