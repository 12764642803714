import styled from 'styled-components';

import {
  alignItems,
  borderRadius,
  boxShadow,
  color,
  display,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  justifyContent,
  lineHeight,
  minWidth,
  position,
  space,
  width,
} from 'styled-system';

import {
  fill,
  hoverBg,
  hoverBoxShadow,
  hoverColor,
  hoverFill,
  pointerEvents,
} from '../../utils/customProperties';

export const TextElement = styled.span`
  color: currentColor;
  height: 30px;
  line-height: 26px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  ${color};

  &:hover {
    color: ${props => props.hoverColor};
  }
`;

export const ButtonElement = styled.button`
  color: currentColor;
  box-shadow: 0 0 0 1px currentColor;
  height: 30px;
  line-height: 26px;
  ${alignItems};
  ${borderRadius};
  ${boxShadow};
  ${color};
  ${display};
  ${fill};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${justifyContent};
  ${lineHeight};
  ${minWidth};
  ${pointerEvents};
  ${position};
  ${width};
  ${space};
  appearance: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline-style: none;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    ${hoverBg};
    ${hoverBoxShadow};
    ${hoverColor};
    ${hoverFill};
    transform: translateY(-1px);
    background-color: currentColor;
  }

  &:active {
    transform: translateY(0);
  }
`;
