import React from 'react';
import PropTypes from 'prop-types';

import theme from 'utils/theme.json';
import { Col, Sticky, Hover } from './styles';

export default function ColNumber({ children, fontSize, isActive, ...props }) {
  return (
    <Col fontSize={fontSize} isActive={isActive}>
      <Sticky {...props}>{children}</Sticky>
      {!isActive && <Hover />}
    </Col>
  );
}

ColNumber.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf(Object.keys(theme.fontSizes).map(Number)),
  ]),
  isActive: PropTypes.bool,
};

ColNumber.defaultProps = {
  fontSize: 3,
  isActive: false,
};
