require('normalize.css/normalize.css');
require('./src/media/css/global.css');
require('./src/media/css/fonts.css');
require('./src/utils/global-styles');

// Delay scroll to top on route change
// https://github.com/gatsbyjs/gatsby/issues/7921
const transitionDelay = 250;

exports.shouldUpdateScroll = () => false;

exports.onRouteUpdate = () =>
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
