import React from 'react';
import PropTypes from 'prop-types';

function Twitter({ size }) {
  return (
    <svg width={size} height="20" viewBox="0 0 24 19.5">
      <path
        d="M24,4.56a9.87,9.87,0,0,1-2.83.77,4.94,4.94,0,0,0,2.17-2.72A10,10,0,0,1,20.21,3.8a4.93,4.93,0,0,0-8.39,4.49A14,14,0,0,1,1.67,3.15,4.93,4.93,0,0,0,3.19,9.72,4.79,4.79,0,0,1,1,9.11,4.93,4.93,0,0,0,4.91,14a4.86,4.86,0,0,1-2.22.08,4.92,4.92,0,0,0,4.6,3.42,9.91,9.91,0,0,1-7.29,2,13.92,13.92,0,0,0,7.55,2.21,13.92,13.92,0,0,0,14-14.64A10.1,10.1,0,0,0,24,4.56Z"
        transform="translate(0 -2.25)"
      />
    </svg>
  );
}

Twitter.propTypes = {
  size: PropTypes.number,
};

Twitter.defaultProps = {
  size: 24,
};

export default Twitter;
