import styled from 'styled-components';
import { display, flexWrap, position } from 'styled-system';

const Wrap = styled.div`
  ${display};
  ${flexWrap};
  ${position};
  width: 100%;
  top: 0;
  bottom: 0;
  pointer-events: none;
`;

export default Wrap;
