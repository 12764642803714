import styled, { css } from 'styled-components';
import theme from 'utils/theme.json';

const Button = styled.button`
  width: 100%;
  padding-top: 100%;
  position: relative;
  display: block;
  border: none;
  cursor: pointer;
  outline: none;
  color: currentColor;
  transition-duration: ${theme.timing[1]}ms;

  &:after {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 1px;
    content: '';
    opacity: 0.1;
    background-color: currentColor;
  }

  ${props =>
    props.kind === 'light' &&
    css`
      fill: currentColor;
      background-color: transparent;

      &:hover {
        fill: ${theme.colors.white};
        background-color: currentColor;
      }
    `};

  ${props =>
    props.kind === 'dark' &&
    css`
      fill: ${theme.colors.white};
      background-color: currentColor;

      &:hover {
        fill: currentColor;
        background-color: ${theme.colors.white};
      }
    `};
`;

const Inner = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Button, Inner };
