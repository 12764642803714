import styled, { css } from 'styled-components';
import theme from 'utils/theme.json';
import { display, position, color } from 'styled-system';

export const AnchorElement = styled.span`
  ${color};
  ${display};
  ${position};

  cursor: pointer;
  text-decoration: none;
  transition-duration: ${theme.timing[1]}ms;

  ${props =>
    props.hasUnderline &&
    css`
      &:after {
        width: 100%;
        height: 1px;
        display: block;

        position: absolute;
        bottom: -3px;
        left: 0;
        content: '';

        opacity: 0.2;
        background-color: currentColor;
        transition-duration: ${theme.timing[1]}ms;
      }

      &:hover:after {
        transform: translateY(-1px);
        opacity: 0.6;
      }
    `};
`;
