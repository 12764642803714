import React from 'react';

function Dropdown() {
  return (
    <svg height="6" viewBox="0 0 10 6" width="10">
      <path
        d="m5 6-5-4.76470588 1.2962963-1.23529412 3.7037037 3.52941176 3.7037037-3.52941176 1.2962963 1.23529412z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Dropdown;
