import React from 'react';
import RehypeReact from 'rehype-react';
import AnchorComponent from './AnchorComponent';
import HeadingComponent from './HeadingComponent';
import ImageComponent from './ImageComponent';
import TextComponent from './TextComponent';

export const bioComponents = {
  a: AnchorComponent(),
  img: ImageComponent(),
  p: TextComponent({
    fontFamily: 1,
    fontSize: 3,
    marginBottom: 0,
    marginTop: 0,
  }),
  h1: HeadingComponent({ tag: 1, fontSize: 7 }),
  h2: HeadingComponent({ tag: 2, fontSize: 6 }),
  h3: HeadingComponent({ tag: 3, fontSize: 5 }),
  h4: TextComponent({
    fontFamily: 0,
    fontSize: 3,
    marginBottom: 3,
    marginTop: 1,
  }),
};

export const renderBio = new RehypeReact({
  createElement: React.createElement,
  components: bioComponents,
}).Compiler;

export const postComponents = {
  a: AnchorComponent(),
  img: ImageComponent(),
  p: TextComponent({
    fontFamily: 1,
    fontSize: 5,
    marginBottom: 2,
    marginTop: 3,
  }),
  h1: HeadingComponent({ tag: 1, fontSize: 7 }),
  h2: HeadingComponent({ tag: 2, fontSize: 6 }),
  h3: HeadingComponent({ tag: 3, fontSize: 5 }),
  h4: TextComponent({
    fontFamily: 0,
    fontSize: 3,
    marginBottom: 3,
    marginTop: 1,
  }),
};

export const renderPost = new RehypeReact({
  createElement: React.createElement,
  components: postComponents,
}).Compiler;

export const pageComponents = {
  a: AnchorComponent(),
  img: ImageComponent(),
  p: TextComponent({
    fontFamily: 0,
    fontSize: 3,
    marginBottom: 2,
    marginTop: 2,
  }),
  h1: HeadingComponent({ tag: 1, fontSize: 7 }),
  h2: HeadingComponent({ tag: 2, fontSize: 6 }),
  h3: HeadingComponent({ tag: 3, fontSize: 5 }),
  h4: TextComponent({
    fontFamily: 0,
    fontSize: 3,
    marginBottom: 3,
    marginTop: 1,
  }),
};

export const renderPage = new RehypeReact({
  createElement: React.createElement,
  components: pageComponents,
}).Compiler;
