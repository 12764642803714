import { injectGlobal } from 'styled-components';
import theme from 'utils/theme.json';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  body {
    font-family: ${theme.fonts[0]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }
}
`;
