import styled from 'styled-components';
import { space, fontSize } from 'styled-system';

export const Sticky = styled.div`
  ${space};
  position: sticky;
  top: 0;
  left: 0;
`;

export const Col = styled.div`
  ${fontSize};
  text-align: center;
  color: currentColor;
  opacity: ${props => (props.isActive ? 1 : 0.4)};
  height: 100%;
  width: 60px;
  cursor: ${props => (props.isActive ? 'initial' : 'pointer')};
  position: ${props => (props.isActive ? 'absolute' : 'inherit')};
  top: 0;
`;

export const Hover = styled.div`
  background-color: currentColor;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: '';
  transition-duration: 300ms;

  &:hover {
    opacity: 0.1;
  }
`;
