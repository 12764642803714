import styled from 'styled-components';
import { fontSize, space, color, fontWeight } from 'styled-system';

const Wrap = styled.span`
  ${fontSize};
  ${space};
  ${color};
  ${fontWeight};
  display: ${props => props.display};
  font-family: ${props => props.fonts[0]};
  line-height: 1.3em;
  max-width: ${props =>
    props.maxWidth !== null ? `${props.maxWidth}px` : '100%'};
`;

export default Wrap;
