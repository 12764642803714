import React from 'react';
import PropTypes from 'prop-types';

function Mail({ size }) {
  return (
    <svg width={size} height="18" viewBox="0 0 22 18">
      <path
        d="M1.48,2.12,12,7.83,22.48,2.12A1,1,0,0,1,23,2V2a2,2,0,0,0-2-2H3A2,2,0,0,0,1,2H1A1,1,0,0,1,1.48,2.12Z"
        transform="translate(-1)"
      />
      <path
        d="M12.45,9.85a1,1,0,0,1-.95,0L1,4.14V16a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V4.12Z"
        transform="translate(-1)"
      />
    </svg>
  );
}

Mail.propTypes = {
  size: PropTypes.number,
};

Mail.defaultProps = {
  size: 22,
};

export default Mail;
