import React from 'react';
import PropTypes from 'prop-types';

import { BoxElement } from './styles';

function Box({ children, display, position, ...props }) {
  return (
    <BoxElement display={display} position={position} {...props}>
      {children}
    </BoxElement>
  );
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['absolute', 'relative', 'static', 'initial']),
  display: PropTypes.any,
};

Box.defaultProps = {
  position: 'relative',
  display: 'block',
};

export default Box;
