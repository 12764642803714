export const getExcerpt = (string, limit) => {
  if (!string) return null;
  return `${string.substr(0, string.lastIndexOf(' ', limit))}...`;
};

export function getPostQuote(title, author, siteTitle) {
  return `${title} by ${author} on ${siteTitle}`;
}

export function getPageQuote(title) {
  return `${title}:`;
}
