export function loadImage(url) {
  if (!url) {
    return null;
  }
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = url;
  });
}
