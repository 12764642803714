import React from 'react';
import PropTypes from 'prop-types';

import theme from 'utils/theme.json';
import Wrap from './styles';

const headingTags = ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label'];

function Heading({ children, display, fontSize, maxWidth, tag, ...props }) {
  const Tag = Wrap.withComponent(headingTags[tag]);
  return (
    <Tag
      fonts={theme.fonts}
      fontSize={fontSize}
      maxWidth={maxWidth}
      display={display}
      {...props}
    >
      {children}
    </Tag>
  );
}

Heading.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  fontWeight: PropTypes.any,
  display: PropTypes.oneOf(['inline', 'block', 'inline-block']),
  fontSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf(Object.keys(theme.fontSizes).map(Number)),
  ]),
  maxWidth: PropTypes.number,
  tag: PropTypes.oneOf(Object.keys(headingTags).map(Number)),
};

Heading.defaultProps = {
  color: 'currentColor',
  display: 'inline-block',
  fontSize: 6,
  fontWeight: 600,
  maxWidth: null,
  tag: 0,
};

export default Heading;
