import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { Flex } from 'grid-styled';

import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Button from 'components/Button';
import ListNumber from 'components/ListNumber';
import NewsletterContainer from 'components/NewsletterContainer';
import PageWrap from 'components/PageWrap';
import Text from 'components/Text';

import { getTwitterUrl, getInstagramUrl, getFacebookUrl } from 'utils/urls';

import romanize from 'utils/romanize';

export default function Footer({ size, padding, pages, isDark, social }) {
  const activeStyle = {
    opacity: 0.4,
    display: 'inline-block',
    pointerEvents: 'none',
  };
  const twitterAnchor = social && (
    <Anchor fontSize={2} href={getTwitterUrl(social.twitter)} target="_blank">
      Twitter
    </Anchor>
  );
  const facebookAnchor = social && (
    <Anchor fontSize={2} href={getFacebookUrl(social.facebook)} target="_blank">
      Facebook
    </Anchor>
  );
  const instagramAnchor = social && (
    <Anchor
      fontSize={2}
      href={getInstagramUrl(social.instagram)}
      target="_blank"
    >
      Instagram
    </Anchor>
  );
  return (
    <Box py={4}>
      <PageWrap space={padding} maxWidth={size}>
        <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
          <Box>
            {pages &&
              pages.map((page, index) => (
                <Box mb={2} key={page.node.number}>
                  <ListNumber>{romanize(index + 1)}.</ListNumber>
                  <Text fontSize={2}>
                    <Anchor
                      to={`/${page.node.slug}/`}
                      activeStyle={activeStyle}
                      tag={Link}
                    >
                      {page.node.title}
                    </Anchor>
                  </Text>
                </Box>
              ))}
            {social && (
              <Box mt={2}>
                <ListNumber>
                  {romanize(pages ? pages.length + 1 : 1)}.
                </ListNumber>
                <Text fontSize={2}>
                  Follow us on {twitterAnchor}, {facebookAnchor} and{' '}
                  {instagramAnchor}
                </Text>
              </Box>
            )}
            <Box mt={2}>
              <ListNumber>{romanize(pages ? pages.length + 2 : 3)}.</ListNumber>
              <NewsletterContainer isDark={isDark} />
            </Box>
            <Box mt={2}>
              <ListNumber>{romanize(pages ? pages.length + 3 : 3)}.</ListNumber>
              <Text fontSize={2}>
                <Anchor
                  href="https://www.iubenda.com/privacy-policy/58474091"
                  target="_blank"
                >
                  Privacy Policy
                </Anchor>
              </Text>
            </Box>
            <Box mt={2}>
              <ListNumber>{romanize(pages ? pages.length + 4 : 2)}.</ListNumber>
              <Text fontSize={2}>
                Mal is supported by{' '}
                <Anchor href="https://feeld.app.link/Mal" target="_blank">
                  Feeld
                </Anchor>
              </Text>
            </Box>
          </Box>
          <Box mt={[40, 0]}>
            <a
              href="https://mal-journal.myshopify.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                bg="transparent"
                fontSize={2}
                fontWeight={600}
                hoverColor={isDark ? 'black' : 'white'}
              >
                Shop
              </Button>
            </a>
          </Box>
        </Flex>
      </PageWrap>
    </Box>
  );
}

Footer.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  pages: PropTypes.array,
  size: PropTypes.number,
  social: PropTypes.object,
};

Footer.defaultProps = {
  padding: undefined,
  pages: undefined,
  social: undefined,
  size: 42,
};
