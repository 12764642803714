import React from 'react';

import { AnchorElement } from './styles';

function Anchor({ children, tag, ...props }) {
  if (tag) {
    const WithTag = AnchorElement.withComponent(tag);
    return <WithTag {...props}>{children}</WithTag>;
  }
  return <AnchorElement {...props}>{children}</AnchorElement>;
}

Anchor.defaultProps = {
  color: 'currentColor',
  display: 'inline',
  hasUnderline: true,
  position: 'relative',
  tag: 'a',
};

export default Anchor;
