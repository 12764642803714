import React from 'react';
import PropTypes from 'prop-types';

import theme from 'utils/theme.json';
import Wrap from './styles';

function ListNumber({ children, fontSize }) {
  return (
    <Wrap
      fontSize={fontSize}
      position={['relative', 'relative', 'relative', 'absolute']}
      left={[0, 0, 0, '-10%']}
    >
      {children}
    </Wrap>
  );
}

ListNumber.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf(Object.keys(theme.fontSizes).map(Number)),
  ]),
};

ListNumber.defaultProps = {
  fontSize: 3,
};

export default ListNumber;
