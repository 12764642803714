import React from 'react';

import Heading from '../Heading';

import { InputElement } from './styles';

export default function InputWithLabel({ label, id, isDark, ...rest }) {
  const color = isDark ? 'black' : 'white';
  return (
    <div>
      <Heading
        color={color}
        display="block"
        fontSize={4}
        htmlFor={id}
        mb={1}
        tag={7}
      >
        {label}
      </Heading>
      <InputElement
        width={1}
        height={34}
        isDark={isDark}
        fontSize={3}
        bg="transparent"
        id={id}
        {...rest}
      />
    </div>
  );
}
