import styled from 'styled-components';

const Wrap = styled.div`
  width: 60px;
  position: sticky;
  top: 0;
  left: 0;
`;

export default Wrap;
