import styled from 'styled-components';
import { space } from 'styled-system';

const Wrap = styled.div`
  ${space};
  margin: 0 auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : props.size)}em;
  width: ${props => (props.maxWidth ? 'auto' : `${props.width}em`)};

  &:after {
    clear: both;
    content: '';
    display: table;
  }
`;

export default Wrap;
