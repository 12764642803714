function getDate(string) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(string);
  return `${monthNames[date.getMonth()]}, ${date.getFullYear()}`;
}

export { getDate };
