import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Heading from 'components/Heading';
import ListNumber from 'components/ListNumber';
import PageWrap from 'components/PageWrap';
import Text from 'components/Text';

import { fadeIn } from 'utils/transitions';
import { getDate } from 'utils/date';
import { getPostUrl } from 'utils/urls';
import { renderPage } from 'components/Markdown';

import Opacity from './styles';

class Issue extends Component {
  componentDidMount() {
    if (this.animeContainer) {
      fadeIn(this.animeContainer.childNodes);
    }
  }

  render() {
    const {
      activePostId,
      date,
      description,
      number,
      padding,
      posts,
      slug,
      title,
    } = this.props;

    const innerRef = n => {
      this.animeContainer = n;
    };

    return (
      <PageWrap space={padding} maxWidth={42} position="relative">
        <Box pt={2} pb={4} innerRef={innerRef}>
          <Box>
            <Heading fontSize={5} tag={1} m={0}>
              {title}
            </Heading>
          </Box>
          <Box mb={3}>
            <Text fontSize={3}>{getDate(date)}</Text>
          </Box>
          <Box mb={4} maxWidth={480}>
            {renderPage(description.childMarkdownRemark.htmlAst)}
          </Box>
          {posts &&
            posts.map((node, index) => (
              <Box mb={3} key={node.id}>
                <ListNumber>{index + 1}.</ListNumber>
                <Opacity value={node.id === activePostId ? 0.3 : 1}>
                  <Anchor
                    to={getPostUrl(number, slug, node.author.slug, node.slug)}
                    tag={Link}
                  >
                    <Box>
                      <Text fontSize={4}>{node.author.name}</Text>
                    </Box>
                    <Heading fontSize={4} tag={2} display="inline">
                      {node.title}
                    </Heading>
                  </Anchor>
                </Opacity>
              </Box>
            ))}
        </Box>
      </PageWrap>
    );
  }
}

Issue.propTypes = {
  activePostId: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  posts: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Issue.defaultProps = {
  activePostId: undefined,
  padding: undefined,
};

export default Issue;
