import styled from 'styled-components';
import { display, space } from 'styled-system';

export const Dialog = styled.div`
  ${space};
  max-width: 380px;
  position: relative;
  background-color: currentColor;
  transform: translateY(${props => (props.isActive ? '0px' : '20px')});
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const ClickHandler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const Modal = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${display};
  overflow: auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 20px;

  background-color: ${props =>
    props.isDark ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.5)'};
  opacity: ${props => (props.isActive ? 1 : 0)};
  pointer-events: ${props => (props.isActive ? 'all' : 'none')};
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const Hover = styled.span`
  opacity: 0.6;
  cursor: pointer;
  transition: all 200ms;

  &:hover {
    opacity: 1;
  }
`;
