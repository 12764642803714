import { ISSUE_LENGTH, SIDE_PADDING } from 'constants/padding';
import IndexPage from 'pages/index';
import IssueTemplate from 'templates/issue';
import Post from 'templates/post';

function getPadding(number) {
  return number * ISSUE_LENGTH + SIDE_PADDING * 2;
}

function getPaddingFromComponent(component, number) {
  let padding;
  if (component === IndexPage || component === IssueTemplate) {
    padding = getPadding(number);
  } else if (component === Post) {
    padding = getPadding(1);
  } else {
    padding = 3;
  }
  return [3, 3, padding, padding];
}

export { getPadding, getPaddingFromComponent };
