import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Column from 'components/Column';
import ColumnWrap from 'components/ColumnWrap';
import Issue from 'components/Issue';
import IssueSelect from 'components/IssueSelect';
import Position from 'components/Position';

import { getPadding } from 'utils/padding';

function IssuesList({ activePostId, issue, issues }) {
  return (
    <div>
      <Box display={['block', 'block', 'none', 'none']}>
        <IssueSelect
          issues={issues}
          activeIssue={issue}
          activePostId={activePostId}
        />
      </Box>
      <Position type="relative">
        <ColumnWrap>
          {issues.map(({ node }) => (
            <Column
              filler={issue === node.number}
              key={node.number}
              number={node.number}
              right={issue > node.number}
              slug={node.slug}
            />
          ))}
        </ColumnWrap>
        {issues.map(
          ({ node }) =>
            issue === node.number && (
              <Issue
                activePostId={activePostId}
                date={node.date}
                description={node.description}
                key={node.number}
                number={node.number}
                padding={[
                  3,
                  3,
                  getPadding(issues.length),
                  getPadding(issues.length),
                ]}
                posts={node.posts}
                slug={node.slug}
                title={node.name}
              />
            ),
        )}
      </Position>
    </div>
  );
}

IssuesList.propTypes = {
  activePostId: PropTypes.string,
  issue: PropTypes.number.isRequired,
  issues: PropTypes.array.isRequired,
};

IssuesList.defaultProps = {
  activePostId: undefined,
};

export default IssuesList;
