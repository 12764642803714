/**
 *
 * Text
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import theme from 'utils/theme.json';

import Wrap from './styles';

function Text({
  children,
  display,
  fontFamily,
  fontSize,
  fontWeight,
  maxWidth,
  ...props
}) {
  return (
    <Wrap
      display={display}
      fontFamily={theme.fonts[fontFamily]}
      fontSize={fontSize}
      fontWeight={fontWeight}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Wrap>
  );
}

Text.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  display: PropTypes.oneOf(['inline', 'block', 'inline-block']),
  fontFamily: PropTypes.oneOf(Object.keys(theme.fonts).map(Number)),
  fontSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf(Object.keys(theme.fontSizes).map(Number)),
  ]),
  fontWeight: PropTypes.oneOf([300, 400, 600]),
  maxWidth: PropTypes.number,
  m: PropTypes.any,
};

Text.defaultProps = {
  color: 'currentColor',
  display: 'block',
  fontFamily: 0,
  fontSize: 3,
  fontWeight: 400,
  maxWidth: null,
  m: 0,
};

export default Text;
