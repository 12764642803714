import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Column from 'components/Column';
import ColumnWrap from 'components/ColumnWrap';
import Heading from 'components/Heading';
import IssuesList from 'components/IssuesList';
import Page from 'components/Page';
import PageWrap from 'components/PageWrap';
import Position from 'components/Position';
import Sidebar from 'components/Sidebar';
import SidebarMobile from 'components/SidebarMobile';

import Line from 'components/Footer/styles';

import { fadeIn } from 'utils/transitions';
import { getIssueUrl, getFullPath, getGlobalAuthorUrl } from 'utils/urls';
import { renderBio, renderPost } from 'components/Markdown';
import { getPadding } from 'utils/padding';
import { getPostQuote } from 'utils/text';

class Post extends Component {
  state = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
    fadeIn(this.animeContainer.childNodes);
  }

  render() {
    const { location, pageContext } = this.props;
    const { image, issues, post, siteTitle, social } = pageContext;
    const { hasMounted } = this.state;
    const { color, bodyColor } = post.issue[0];
    const isDark = post.issue[0].darkMode;
    // eslint-disable-next-line
    const backgroundColor = post.issue[0].backgroundColor;
    const padding = [3, 3, getPadding(1), getPadding(1)];

    const pageProps = {
      color,
      image,
      isDark,
      backgroundColor,
      location,
      social,
      description: post.description.description,
      title: `${post.title} – ${post.author.name} – ${siteTitle}`,
    };

    const innerRef = node => {
      this.animeContainer = node;
    };

    const backUrl = getIssueUrl(post.issue[0].number, post.issue[0].slug);
    const shareUrl = getFullPath(process.env.SITE_URL, location);
    const authorUrl = getGlobalAuthorUrl(post.author.slug);
    const quote = getPostQuote(post.title, post.author.name, siteTitle);
    const emailProps = {
      subject: post.title,
      body: `${quote}:\n\n${shareUrl}`,
      url: shareUrl,
    };

    return (
      <Page {...pageProps}>
        <Box opacity={hasMounted ? 1 : 0}>
          <Position type="relative">
            <Box display={['block', 'block', 'none', 'none']}>
              <SidebarMobile
                backUrl={backUrl}
                emailProps={emailProps}
                quote={quote}
                shareUrl={shareUrl}
                social={social}
              />
            </Box>
            <PageWrap maxWidth={42} space={padding}>
              <Box pt={8} pb={5} innerRef={innerRef}>
                <Box mb={1}>
                  <Heading tag={1} fontSize={7} color={bodyColor} m={0}>
                    {post.title}
                  </Heading>
                </Box>
                <Box mb={2}>
                  <Heading tag={2} fontSize={5} color={bodyColor} m={0}>
                    <Anchor to={authorUrl} target="_blank" tag={Link}>
                      {post.author.name}
                    </Anchor>
                  </Heading>
                </Box>
                {post.content && (
                  <Box color={bodyColor}>
                    {renderPost(post.content.childMarkdownRemark.htmlAst)}
                  </Box>
                )}
                {post.author.bio && (
                  <Box mt={4}>
                    <Box fontFamily={1} fontSize={1} color={bodyColor}>
                      {renderBio(post.author.bio.childMarkdownRemark.htmlAst)}
                    </Box>
                  </Box>
                )}
                {post.author.socialLinks && (
                  <Box mt={4}>
                    <Box fontFamily={1} color={bodyColor}>
                      {post.author.socialLinks.map(link => (
                        <Box mb={1} key={link}>
                          <Anchor href={link} target="_blank">
                            {link}
                          </Anchor>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </PageWrap>
            <ColumnWrap>
              <Column right>
                <Sidebar
                  backUrl={backUrl}
                  emailProps={emailProps}
                  quote={quote}
                  shareUrl={shareUrl}
                  social={social}
                />
              </Column>
            </ColumnWrap>
          </Position>
          <Line width={1} />
          <Box pt={5}>
            <IssuesList
              activePostId={post.id}
              issue={post.issue[0].number}
              issues={issues}
            />
          </Box>
        </Box>
      </Page>
    );
  }
}

Post.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    image: PropTypes.string,
    issues: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    post: PropTypes.object.isRequired,
    siteTitle: PropTypes.string.isRequired,
    social: PropTypes.object.isRequired,
  }),
};

Post.defaultProps = {
  pageContext: undefined,
};

export default Post;
