import React from 'react';
import { SvgElement } from './styles';

function Svg({ children, ...props }) {
  return <SvgElement {...props}>{children}</SvgElement>;
}

Svg.defaultProps = {
  fill: 'currentColor',
};

export default Svg;
