import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Box from 'components/Box';
import IssuesList from 'components/IssuesList';
import Page from 'components/Page';

class IndexPage extends Component {
  state = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
  }

  render() {
    const { data, location } = this.props;
    const { hasMounted } = this.state;
    const issues = data.issues.edges;
    const metadata = data.allContentfulMetadata.edges[0].node;
    const pageProps = {
      location,
      image: metadata.defaultImage ? metadata.defaultImage.file.url : undefined,
      color: issues ? issues[issues.length - 1].node.color : '',
      description: metadata.description,
      title: metadata.title,
      isDark: issues ? issues[issues.length - 1].node.darkMode : false,
      backgroundColor: issues
        ? issues[issues.length - 1].node.backgroundColor
        : undefined,
      social: {
        twitter: metadata.twitterHandle,
        instagram: metadata.instagramUsername,
        facebook: metadata.facebookPage,
        messengerLink: metadata.messengerLink,
        feeldLink: metadata.feeldLink,
      },
    };

    return (
      <Page {...pageProps}>
        <Box opacity={hasMounted ? 1 : 0}>
          <IssuesList issues={issues} issue={issues.length} />
        </Box>
      </Page>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query IndexQuery {
    issues: allContentfulIssue(sort: { fields: [number], order: ASC }) {
      edges {
        node {
          id
          color
          number
          name
          date
          slug
          darkMode
          backgroundColor
          metaDescription
          image {
            file {
              url
            }
          }
          description {
            description
            childMarkdownRemark {
              htmlAst
            }
          }
          posts {
            id
            number
            slug
            title
            image {
              file {
                url
              }
            }
            author {
              name
              slug
            }
            content {
              content
            }
          }
        }
      }
    }
    allContentfulMetadata {
      edges {
        node {
          title
          description
          twitterHandle
          facebookPage
          instagramUsername
          messengerLink
          feeldLink
          defaultImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
