import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import IssuesList from 'components/IssuesList';
import Page from 'components/Page';

class IssueTemplate extends Component {
  state = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
  }

  render() {
    const { hasMounted } = this.state;
    const { location, pageContext } = this.props;
    const { issue, issues, siteTitle, social, image } = pageContext;

    const pageProps = {
      image,
      location,
      social,
      color: issue.color,
      description: issue.metaDescription,
      title: `${issue.name} – ${siteTitle}`,
      isDark: issue.darkMode,
      backgroundColor: issue.backgroundColor,
    };

    return (
      <Page {...pageProps}>
        <Box opacity={hasMounted ? 1 : 0}>
          <IssuesList issues={issues} issue={issue.number} />
        </Box>
      </Page>
    );
  }
}

IssueTemplate.propTypes = {
  pageContext: PropTypes.shape({
    image: PropTypes.string,
    issue: PropTypes.object.isRequired,
    issues: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    siteTitle: PropTypes.string.isRequired,
    social: PropTypes.object.isRequired,
  }),
};

IssueTemplate.defaultProps = {
  pageContext: undefined,
};

export default IssueTemplate;
