import React from 'react';

import Text from 'components/Text';

/* eslint-disable react/prop-types */
const TextComponent = ({
  fontFamily,
  fontSize,
  marginBottom,
  marginTop,
}) => props => (
  <Text
    fontFamily={fontFamily}
    fontSize={fontSize}
    mb={marginBottom}
    mt={marginTop}
  >
    {props.children}
  </Text>
);

export default TextComponent;
