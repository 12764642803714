import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import Arrow from 'components/Icons/Arrow';
import Facebook from 'components/Icons/Facebook';
import Mail from 'components/Icons/Mail';
import Twitter from 'components/Icons/Twitter';

import { ButtonBar, Button, Filler } from './styles';

function SidebarMobile({
  backUrl,
  emailProps,
  onBackButtonClick,
  quote,
  shareUrl,
  social,
}) {
  return (
    <ButtonBar mb={3}>
      <Filler>
        {backUrl ? (
          <Link to={backUrl}>
            <Button right>
              <Arrow />
            </Button>
          </Link>
        ) : (
          <Button onClick={onBackButtonClick} right>
            <Arrow />
          </Button>
        )}
        <Filler>
          <FacebookShareButton url={shareUrl} quote={quote}>
            <Button left>
              <Facebook size={10} />
            </Button>
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={quote} via={social.twitter}>
            <Button left>
              <Twitter size={18} />
            </Button>
          </TwitterShareButton>
          <EmailShareButton {...emailProps}>
            <Button left>
              <Mail size={16} />
            </Button>
          </EmailShareButton>
        </Filler>
      </Filler>
    </ButtonBar>
  );
}

SidebarMobile.propTypes = {
  backUrl: PropTypes.string.isRequired,
  emailProps: PropTypes.object.isRequired,
  onBackButtonClick: PropTypes.func,
  quote: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
  social: PropTypes.object.isRequired,
};

export default SidebarMobile;
