import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import { fadeIn } from 'utils/transitions';
import { getIssueUrl } from '../../../utils/urls';
import { IssuesDropdown, IssueItem } from './styles';

class Issues extends PureComponent {
  componentWillReceiveProps(newProps) {
    if (newProps.isOpen) {
      fadeIn(this.animeContainer.childNodes);
    }
  }

  render() {
    const { activeIssue, issues, isOpen } = this.props;
    const innerRef = node => {
      this.animeContainer = node;
    };
    return (
      <IssuesDropdown
        isOpen={isOpen}
        innerRef={innerRef}
        opacity={isOpen ? 1 : 0}
      >
        {issues.map(({ node }) => (
          <Link key={node.number} to={getIssueUrl(node.number, node.slug)}>
            <IssueItem
              color="white"
              fontSize={3}
              isActive={activeIssue === node.number}
              p={3}
            >
              Nº
              {node.number}
              &nbsp; &nbsp;
              {node.name}
            </IssueItem>
          </Link>
        ))}
      </IssuesDropdown>
    );
  }
}

Issues.propTypes = {
  activeIssue: PropTypes.number.isRequired,
  issues: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
};

Issues.defaultProps = {
  isOpen: false,
};

export default Issues;
