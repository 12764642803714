import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import includes from 'lodash/includes';

import Box from 'components/Box';
import Footer from 'components/Footer';
import Line from 'components/Line';
import Logo from 'components/Logo';
import PageWrap from 'components/PageWrap';
import Transition from 'components/Transition';

import theme from 'utils/theme.json';

import { getPaddingFromComponent } from 'utils/padding';

const query = graphql`
  query IndexLayoutQuery {
    issues: allContentfulIssue(sort: { fields: [number], order: ASC }) {
      edges {
        node {
          color
          darkMode
          number
        }
      }
    }
    pages: allContentfulPage(sort: { fields: [number], order: ASC }) {
      edges {
        node {
          title
          slug
          number
          content {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
    metadata: allContentfulMetadata {
      edges {
        node {
          title
          description
          twitterHandle
          facebookPage
          instagramUsername
          messengerLink
          feeldLink
        }
      }
    }
  }
`;

/* eslint-disable prefer-destructuring */
class IndexLayout extends React.Component {
  render() {
    const { children, location } = this.props;
    return (
      <StaticQuery
        query={query}
        render={data => {
          let pathNumber = 0;
          if (typeof window !== 'undefined') {
            pathNumber = parseFloat(window.location.pathname.replace('/', ''));
          }
          const issues = data.issues.edges;
          const currentIssue = issues.filter(
            issue => issue.node.number === pathNumber,
          );
          const pages = data.pages.edges;
          const padding = getPaddingFromComponent(children.type, issues.length);
          const metadata = data.metadata.edges[0].node;
          const isDark = currentIssue.length
            ? currentIssue[0].node.darkMode
            : issues[issues.length - 1].node.darkMode;
          const social = {
            twitter: metadata.twitterHandle,
            instagram: metadata.instagramUsername,
            facebook: metadata.facebookPage,
            messengerLink: metadata.messengerLink,
            feeldLink: metadata.feeldLink,
          };
          return (
            <ThemeProvider theme={theme}>
              <Box>
                <PageWrap space={padding}>
                  <Box mb={4}>
                    <Logo />
                  </Box>
                </PageWrap>
                <Transition location={location}>{children}</Transition>
                <Line width={1} />
                <Footer
                  isDark={isDark}
                  padding={padding}
                  pages={pages}
                  social={social}
                />
              </Box>
            </ThemeProvider>
          );
        }}
      />
    );
  }
}

IndexLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default IndexLayout;
