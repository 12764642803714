import styled from 'styled-components';

import {
  color,
  display,
  height,
  maxWidth,
  position,
  space,
  top,
  width,
} from 'styled-system';

import { fill, stroke } from '../../utils/customProperties';

export const SvgElement = styled.svg`
  ${color};
  ${display};
  ${fill};
  ${height};
  ${maxWidth};
  ${position};
  ${space};
  ${stroke};
  ${top};
  ${width};
`;
