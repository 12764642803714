import React from 'react';
import PropTypes from 'prop-types';

import Wrap from './styles';

function ColumnWrap({ children }) {
  return (
    <Wrap
      position="absolute"
      display={['none', 'none', 'flex', 'flex']}
      flexWrap="nowrap"
    >
      {children}
    </Wrap>
  );
}

ColumnWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ColumnWrap;
