import React from 'react';

import { Input, Checkmark, Container, Text } from './styles';

export default function Checkbox({ label, isDark, ...rest }) {
  return (
    <Container isDark={isDark}>
      <Text isDark={isDark}>{label}</Text>
      <Input type="checkbox" {...rest} />
      <Checkmark isDark={isDark} />
    </Container>
  );
}
