import React from 'react';
import PropTypes from 'prop-types';

function Facebook({ size }) {
  return (
    <svg width={size} height="24" viewBox="0 0 12 24">
      <path
        d="M9,8H6v4H9V24h5V12h3.64L18,8H14V6.33c0-1,.19-1.33,1.11-1.33H18V0H14.19C10.6,0,9,1.58,9,4.61Z"
        transform="translate(-6)"
      />
    </svg>
  );
}

Facebook.propTypes = {
  size: PropTypes.number,
};

Facebook.defaultProps = {
  size: 12,
};

export default Facebook;
