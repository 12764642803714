import React, { Component } from 'react';

import Anchor from '../Anchor';
import Box from '../Box';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Fieldset from '../Fieldset';
import InputWithLabel from '../InputWithLabel';
import Text from '../Text';

export default class Form extends Component {
  render() {
    const { isDark } = this.props;
    const color = isDark ? 'black' : 'white';
    return (
      <form
        action="https://maljournal.us3.list-manage.com/subscribe/post?u=7af13799e8217730e2adcc61d&id=3d5d5d7640"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        <Box mb={3}>
          <InputWithLabel
            id="mce-EMAIL"
            label="Email Address"
            name="EMAIL"
            placeholder="Enter your email"
            type="email"
            isDark={isDark}
          />
        </Box>

        <Box mb={3}>
          <InputWithLabel
            id="mce-FNAME"
            label="First Name"
            name="FNAME"
            placeholder="Enter your first name"
            type="text"
            isDark={isDark}
          />
        </Box>

        <Box mb={3}>
          <InputWithLabel
            id="mce-LNAME"
            label="Last Name"
            name="LNAME"
            placeholder="Enter your last name name"
            type="text"
            isDark={isDark}
          />
        </Box>

        <Fieldset m={0} p={0} name="interestgroup_field">
          <Checkbox
            label="I would like to receive Mal's newsletter via email"
            type="checkbox"
            id="gdpr_1"
            name="gdpr[1]"
            isDark={isDark}
            defaultChecked
          />
        </Fieldset>

        <Text my={2} color={color}>
          You can unsubscribe at any time by clicking the link in the footer of
          our emails. For information about our privacy practices, please visit
          our website.
        </Text>

        <Text fontSize={2} my={2} color={color} opacity={0.7}>
          We use Mailchimp as our marketing platform. By clicking below to
          subscribe, you acknowledge that your information will be transferred
          to Mailchimp for processing. Learn more about Mailchimp&apos;s privacy
          practices{' '}
          <Anchor
            href="https://mailchimp.com/legal/"
            target="_blank"
            color={color}
            rel="noopener noreferrer"
          >
            here.
          </Anchor>
        </Text>

        <div id="mce-responses">
          <div id="mce-error-response" style={{ display: 'none' }} />
          <div id="mce-success-response" style={{ display: 'none' }} />
        </div>

        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_7af13799e8217730e2adcc61d_3d5d5d7640"
            tabIndex={-1}
            defaultValue
          />
        </div>

        <Button
          bg="currentColor"
          boxShadow={
            isDark
              ? '0 0 0 1px rgba(0, 0, 0, 0.4)'
              : '0 0 0 1px rgba(255, 255, 255, 0.4)'
          }
          color={color}
          hoverColor={color}
          defaultValue="Subscribe"
          fontSize={2}
          fontWeight={600}
          hoverBoxShadow={
            isDark
              ? '0 0 0 1px rgba(0, 0, 0, 0.6)'
              : '0 0 0 1px rgba(255, 255, 255, 0.6)'
          }
          id="mc-embedded-subscribe"
          isDark={isDark}
          mt={10}
          name="subscribe"
          type="submit"
        >
          Subscribe
        </Button>
      </form>
    );
  }
}
