import styled, { css } from 'styled-components';
import { top, width, bottom, height, left, right } from 'styled-system';

export const Col = styled.div`
  ${props =>
    props.filler === false &&
    css`
      ${width};
      ${height};
      position: relative;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-duration: 600ms;
      pointer-events: all;
    `}

  ${props =>
    props.right === true &&
    css`
      &:after {
        right: 0;
      }
    `}

  ${props =>
    props.right === false &&
    css`
      &:after {
        left: 0;
      }
    `}

  ${props =>
    props.filler === true &&
    css`
      flex-grow: 100;
    `}
`;

export const Border = styled.div`
  ${bottom};
  ${height};
  ${left};
  ${right};
  ${top};
  ${width};
  position: absolute;
  opacity: 0.1;
  background-color: currentColor;
`;
