/* eslint-disable no-mixed-operators */
import anime from 'animejs';

const init = node => {
  anime.remove(node);
  anime({
    targets: node,
    translateY: 20,
    translateZ: 0,
    opacity: 0,
  });
};

const fadeIn = node => {
  anime.remove(node);
  anime({
    targets: node,
    translateY: [20, 0],
    translateZ: 0,
    opacity: [0, 1],
    easing: 'easeOutExpo',
    duration: 1400,
    delay(el, i) {
      return 300 + 40 * i;
    },
  });
};

const fadeOut = node => {
  anime.remove(node);
  anime({
    targets: node,
    translateZ: 0,
    opacity: [1, 0],
    easing: 'easeOutExpo',
    duration: 400,
  });
};

const getTransitionStyles = timeout => ({
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
});

const getTransitionStyle = ({ timeout, status }) =>
  getTransitionStyles(timeout)[status];

export { init, fadeIn, fadeOut, getTransitionStyle };
