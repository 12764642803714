import React from 'react';

import Position from '../Position';

export default function PinRight({ children, ...rest }) {
  return (
    <Position position="absolute" top={0} right={0} {...rest}>
      {children}
    </Position>
  );
}
